html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

nav {
  padding: 20px;
}

a {
  text-decoration: none;
  color: #b29459;
  margin: 0 10px;
}

a:active {
  color: #b29459;
}

.rotate-90 {
  transform: rotate(90);
  top: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (min-width: 800px) {
  .App-header {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
}

.App-header-wrapper-lol img {
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
}

.App-footer {
  background-image: url(./heaven.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.App-header {
  background-image: url(./sky.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  /* background-color: #86c6ee; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.App-link {
  color: #86c6ee;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
